import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BiSolidUserCircle } from 'react-icons/bi';
import { FaUserPlus } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import navlogo from './NavImages/logo.png';
import './Navcss/Navbar.css';

export default function StickyNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Added useNavigate

  const togglenavbarcomp = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigatelogin = () => {
    window.location.href = 'https://dev.sunpay.co.in/login';
  };

  const navigatesignup = () => {
    navigate('/enquiry'); // Replaced window.location.href with useNavigate
  };

  const isTimeInRange = () => {
    const now = new Date();
    const startHour = 7;
    const endHour = 23;
    const currentHour = now.getHours();
    return currentHour >= startHour && currentHour < endHour;
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between px-4 py-3">
        
        {/* Logo */}
        <NavLink to="/" className="flex items-center">
          <img src={navlogo} className="h-[74px] w-40 hover:scale-110 transition-transform duration-300" alt="Logo" />
        </NavLink>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-28">
          {["Home", "About", "Services", "Contact", "Terms & Condition"].map((item, index) => (
            <NavLink
              key={index}
              to={`/${item.toLowerCase().replace(" & ", "-").replace(" ", "-")}`}
              className="text-gray-900 text-lg font-medium hover:text-blue-500 transition-colors duration-300"
            >
              {item}
            </NavLink>
          ))}
        </div>

        {/* Mobile Menu Icon */}
        <button
          className="md:hidden text-2xl"
          onClick={togglenavbarcomp}
        >
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </button>

        {/* Right Side Buttons */}
        <div className="hidden md:flex space-x-4">
          {isTimeInRange() && (
            <div>
              <button
              onClick={navigatelogin}
              className="text-white bg-blue-500 hover:bg-blue-600 rounded-lg text-2xl px-4 py-2 shadow-md transition-transform duration-300 transform hover:scale-110"
              >
              <BiSolidUserCircle />
            </button>
            <br />
            <p className='text-center font-bold text-red-500 animate-blink'>Login</p>
            </div>
          )}
          <div>
            <button onClick={navigatesignup} className="text-white bg-green-500 hover:bg-green-600 rounded-lg text-2xl px-4 py-2 shadow-md transition-transform duration-300 transform hover:scale-110">
              <FaUserPlus />
            </button>
            <br />
            <p className='text-center font-bold text-lime-700 animate-blink'>Sign Up</p>
            </div>
          </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute bg-white w-full left-0 top-[100%] shadow-md transition-transform duration-300">
          <ul className="flex flex-col items-center space-y-4 py-4">
            {["Home", "About", "Services", "Contact", "Terms & Condition"].map((item, index) => (
              <li key={index}>
                <NavLink
                  to={`/${item.toLowerCase().replace(" & ", "-").replace(" ", "-")}`}
                  className="block text-gray-900 text-lg font-medium hover:text-blue-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
}
