import React from 'react'
import { useState, useEffect } from 'react';
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer'
import { ChevronDown } from "lucide-react";

const Faq = () => {
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
  
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
  
      setLastScrollY(currentScrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [lastScrollY]);

    const faqs = [
      { question: "What is your company and what services do you offer?", answer: "We are a fintech company providing secure digital financial solutions in India." },
      { question: "Is your company regulated by the Government of India?", answer: "Yes, we comply with Government regulations for fintech companies in India." },
      { question: "How do I create an account?", answer: "You can sign up using your mobile number and complete KYC verification by your ASM or Distributor." },
      { question: "What documents are required for KYC verification?", answer: "You need Aadhaar, PAN card, Account Details, and a valid aadhaar registered number for OTP verification." },
      { question: "How long does KYC verification take?", answer: "KYC verification typically takes a few minutes to 24 hours." },
      { question: "Is my personal data safe with your company?", answer: "Yes, we use end-to-end encryption and comply with data security standards." },
      { question: "Can I link multiple bank accounts to my account?", answer: "Yes, you can link multiple bank accounts for seamless financial management." },
      { question: "Does your platform support UPI transactions?", answer: "Yes, we provide secure UPI transactions in compliance with NPCI regulations." },
      { question: "What should I do if my transaction fails?", answer: "If your transaction fails, it will be automatically refunded within 48-72 hours." },
      { question: "How do I reset my account password?", answer: "You can reset your password via the 'Forgot Password' option on the security page and call to customer care & then verify details after their verification password would be reset." },
      { question: "Can I apply for a credit/debit card through your company?", answer: "Yes, we offer prepaid and credit cards in partnership with leading banks." },
      { question: "How do I block my card if it is lost or stolen?", answer: "You can block your card instantly through our app or by calling customer support." },
      { question: "What is the daily spending limit on my wallet/card?", answer: "The spending limit varies based on your KYC status and card type." },
      { question: "Is there a way to earn cashback or rewards on transactions?", answer: "Yes, we offer cashback and rewards on eligible transactions." },
      { question: "What should I do if I suspect unauthorized activity on my account?", answer: "Immediately report it via the app or contact customer support for assistance." },
      { question: "How do you ensure secure transactions?", answer: "We use multi-layered security, encryption, and fraud detection systems." },
      { question: "Can I enable two-factor authentication (2FA) for additional security?", answer: "Yes, 2FA is available for login and transactions." },
      { question: "How do I report fraud or a phishing attempt?", answer: "You can report fraud through the app or by emailing our fraud department." },
      { question: "Does your company provide personal or business loans?", answer: "Yes, we offer instant personal and business loans based on eligibility." },
      { question: "What are the eligibility criteria for getting a loan?", answer: "Eligibility is based on credit score, income, and KYC verification." },
      { question: "Can I invest in mutual funds or stocks through your platform?", answer: "Yes, we offer investment options including mutual funds and stock trading." },
      { question: "What is the interest rate on loans provided by your company?", answer: "Interest rates vary based on loan type and applicant profile." },
      { question: "How can I check my credit score through your platform?", answer: "You can check your credit score for free through our app." },
      { question: "Does your company support insurance services?", answer: "Yes, we offer digital insurance services including health and life insurance." },
      { question: "Can I pay bills through your platform?", answer: "Yes, you can pay electricity, water, gas, and other utility bills through our app." },
      { question: "Is your platform GST compliant for business users?", answer: "Yes, we provide GST-compliant invoices and payment solutions for businesses." },
      // { question: "Does your platform support digital gold investments?", answer: "Yes, we allow secure digital gold investments starting at INR 1." },
      { question: "Can I set up auto payments for subscriptions?", answer: "Yes, you can enable auto payments for your subscriptions and bills." },
      { question: "Does your platform have a referral program?", answer: "Yes, you can earn rewards by referring friends and family." },
      { question: "How can I contact customer support?", answer: "You can reach our support team via chat, email, or helpline number." }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };

  return (
    <div >
        {/* Navbar */}
        <div className={`sticky-navbar ${showNavbar ? 'visible' : 'hidden'} `}>
        <StickyNavbar />
        </div>

        <div className="bg-gradient-to-br from-red-500 to-green-300 mt-20 min-h-screen p-6 text-white">
          <h2 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white text-black p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleFAQ(index)}>
                  <h3 className="font-semibold">{faq.question}</h3>
                  <ChevronDown className={`transition-transform ${openIndex === index ? "rotate-180" : ""}`} />
                </div>
                {openIndex === index && <p className="mt-2">{faq.answer}</p>}
              </div>
            ))}
          </div>
    </div>

      {/* Footer */}
      <section className='Footer'>
        <Footer/>
      </section>

    </div>
  )
}

export default Faq