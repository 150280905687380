import React, { useState } from 'react';
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer';

const Enquirepage = () => {
  const [mobile, setmobile] = useState('');
  const [email, setemail] = useState('');
  const [name, setname] = useState('');
  const [state, setstate] = useState('');
  const [message, setmessage] = useState('');
  const [address, setaddress] = useState('');
  const [shop_name, setshopname] = useState('');
  const [popup, setPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPopup(true);
    const formData = {
      mobile,
      name,
      email,
      address,
      state,
      shop_name,
      message,
    };
    
    try {
      const response = await fetch('https://dev.sunpay.co.in/front/query-api', {
        method: 'POST',
        
        headers: {
          
        },
        body: JSON.stringify(formData),
      });
      
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Response:', data);
  
      if (data.status === 'success') {
        console.log("message sent")
      } else {
        alert('Unable to Send Query. Please contact +91 9403891008');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // alert('Error submitting form');
    }
  };
    

  const handlePopupClose = () => {
    setPopup(false);
    setmobile('');
    setemail('');
    setname('');
    setstate('');
    setmessage('');
    setaddress('');
    setshopname('');
  };

  return (
    <div>
      <StickyNavbar />

      <section className="servicesectionhome bg-white pt-28 pb-16">
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold text-center mb-6 text-white">Enquiry Form</h2>
          <div className="max-w-lg mx-auto bg-gray-100 p-6 rounded-lg shadow-md">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block font-semibold mb-1">Name</label>
                <input 
                  type="text" 
                  name="name" 
                  placeholder="Enter Name" 
                  value={name} 
                  onChange={(e) => setname(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <div>
                <label className="block font-semibold mb-1">Mobile</label>
                <input 
                  type="text" 
                  name="mobile" 
                  placeholder="Enter Mobile Number" 
                  value={mobile} 
                  onChange={(e) => setmobile(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <div>
                <label className="block font-semibold mb-1">Email</label>
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Enter Email" 
                  value={email} 
                  onChange={(e) => setemail(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <div>
                <label className="block font-semibold mb-1">State</label>
                <input 
                  type="text" 
                  name="state" 
                  placeholder="Enter State" 
                  value={state} 
                  onChange={(e) => setstate(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <div>
                <label className="block font-semibold mb-1">Message</label>
                <textarea 
                  name="message" 
                  placeholder="Enter Your Message" 
                  value={message} 
                  onChange={(e) => setmessage(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded"
                ></textarea>
              </div>
              <div>
                <label className="block font-semibold mb-1">Shop Name</label>
                <input 
                  type="text" 
                  name="shop_name" 
                  placeholder="Enter Your Shop Name" 
                  value={shop_name} 
                  onChange={(e) => setshopname(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <div>
                <label className="block font-semibold mb-1">Address</label>
                <input 
                  type="text" 
                  name="address" 
                  placeholder="Enter Address" 
                  value={address} 
                  onChange={(e) => setaddress(e.target.value)} 
                  required 
                  className="block w-full p-2 border rounded" 
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 w-full rounded">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

      {popup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold">Your query has been sent!</h2>
            <p className="mt-2">You will receive a callback soon.</p>
            <button 
              onClick={handlePopupClose} 
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              OK
            </button>          
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Enquirepage;
