import React from 'react'
import { NavLink } from 'react-router-dom'
import abimgr from './Images Homepage/aboutsecimg.png'
import StickyNavbar from '../Navbar/StickyNavbar'
import Footer from '../Footer/Footer'
import wmoreimg from './Images Homepage/wh-img-1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

export default function Aboutpage() {
  return (
    <div className='aboutsection'>
       <StickyNavbar></StickyNavbar>
      {/* About Section */}
      <section className='aboutussecdiv bg-white mt-16 flex flex-wrap pb-12'>
          <div className='w-full md:w-3/5'>
            <div className='aboutussechead pt-8 text-center md:text-left'>
              <h1 className='text-5xl md:text-7xl text-orange-500 rounded-lg px-4 md:pl-20 w-full mt-8 break-all inline-block'>
                About us
              </h1>
              <h1 className='text-5xl md:text-7xl text-green-700 rounded-lg px-4 md:pl-4 w-full mt-8 break-all inline-block'>
              
              </h1>
            </div>
            <div className="aboutsectext text-center md:text-left ml-4 md:ml-8 text-xl px-4 md:px-10 mt-6 mb-10">
              Sunpay has emerged as one of the most popular platforms for booking travel services efficiently and affordably. At Sunpay, we're revolutionizing the way you plan your trips, making flight bookings, bus bookings, hotel bookings, and travel bookings easier, faster, and more affordable than ever before.
              <br />Our mission is to empower you to explore the world with the utmost confidence, security, and convenience. Whether you're planning a business trip or a family vacation, Sunpay ensures a seamless and hassle-free experience from start to finish.
              <br />
              <br />
              <NavLink to="/about-us">
                <button className='w-48 h-12 mt-7 text-white bg-gradient-to-r from-orange-500 to-green-500 rounded-lg hover:opacity-80'>
                  Read More
                </button>
              </NavLink>
            </div>
          </div>
          <div className='w-full md:w-2/5 container flex justify-center md:justify-end'>
            <div className='aboutsecimg inline-block'>
              <img src={abimgr} alt="About Us" className='inline-block p-0 max-w-full h-auto'/>
            </div>
          </div>
      </section>

    {/* More about Sunpay Section */}
      <section className='moresunp bg-stone-400 pb-16 px-8 '>
          <div className="moresunp mt-8 flex flex-col lg:flex-row">
            <div className='leftmoresunp w-full lg:w-2/5 lg:ml-20 mt-8 text-center lg:mt-16'>
              <h1 className='text-5xl text-green-700'><b>
              We Are Here To Help You <h1 className='text-5xl text-orange'>Build Your Business</h1>
              </b></h1>

            <p className='mt-6 text-xl w-full overflow-wrap'>
              Welcome to SunPay, your trusted partner in travel services. SunPay is a well-known name in the travel and booking industry. We believe in providing the best services to make our platform reliable and customer-friendly. Our offerings include top-notch solutions for **flight booking, hotel booking, bus booking, and travel booking** across the globe.
            </p>
            <p className='mt-6 text-xl w-full overflow-wrap'>
              Become a part of this innovative platform with minimal investment. Start your journey today for a brighter future. Establishing a higher standard in travel services is key to shaping the global travel market. At SunPay, we provide a robust platform and an efficient booking system, setting new benchmarks in the travel and booking industry.
            </p>
              <br />
              <NavLink to="/about-us" > 
                        <button className='w-48 h-12 mt-7 text-white bg-gradient-to-r from-orange to-green-500 rounded-lg hover:opacity-50'>
                            Read More 
                        </button>
                    </NavLink>  
              </div>
              <div className='rightmoresunp w-full lg:w-1/2 text-center lg:ml-16 mt-8'>
                <div className='boxcontrsunp grid grid-cols-1 sm:grid-cols-2 gap-y-8'>
                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rmoresunpitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rsunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>Easy Integration</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          Sign up and go with easy integration feature for your web and mobile applications.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rmoresunpitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>Faster Response</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          We use RESTFul APIs which deliver fastest response over requests made from web or mobile devices.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rwhitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>24/7 Support</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          Our customer service is best in class and committed to serve you 24x7 for your queries and questions.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rwhitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>For All Platforms</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          No worries of technology your application is built on, we support all of them.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </section> 

      {/* More About Section */}
      <section className='whysunp bg-slate-50'>
        <div className='whysunpcont flex pt-12 pb-12 px-24'>
          <div className='lwhysunp w-1/2'>
            <p className='text-red-600 text-xl bg'>WHY CHOOSE US ? </p><br />
            <h1 className='text-4xl font-black w-5/6'>We Offer Best Professional Services For Your Business</h1>
            <div className='leftwhysunpitemcont mt-12 '>
          
              <div className='lwhysunpitem w-3/4 mb-4 py-4 gap-4 flex'>
                <div className='itemicon'>
                  
                </div>
                <div className="itemtext pr-2">
                  <h1 className='text-2xl'>B2B Platform</h1>
                  <p>Build your own Retailer Distributor Network with bug free travel booking software.</p>              
                </div>
              </div>

              <div className='lwhysunpitem w-3/4 mb-4 py-4 gap-4 flex'>
                <div className='itemicon'>
                  
                </div>
                <div className="itemtext pr-2">
                  <h1 className='text-2xl'>B2C Portals</h1>
                    <p>Online booking portal connected directly with platforms for flight booking, travel booking, hotel booking, bus booking, and other travel-related services.</p>
                  </div>
              </div>

              <div className='lwhysunpitem w-3/4 py-4 gap-4 flex'>
                <div className='itemicon'>

                </div>
                <div className="itemtext pr-2">
                  <h1 className='text-2xl'>Android</h1>
                  <p>Multi recharge software includes Android application as standard feature of software. Publish your own brand name on Playstore.</p>
                </div>
              </div>
            </div>
          </div>

          <div className='rwhysunpimg mt-4 md:mt-12 w-full md:w-1/2 border-4 border-zinc-600'>
                <img src={wmoreimg} alt="" className='w-full h-[500px]'/>
          <div className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl bg-white text-center bottom-0 w-full py-4'>
            100% Trusted Company
          </div>
        </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className='Testimonials bg-slate-500 px-4 sm:px-8 py-10'>
            <div className='testimonials text-center'>
              
              <div className="testheading text-3xl sm:text-4xl md:text-5xl text-white font-extrabold pt-6">
                See What Clients Are Saying
              </div>
              <hr className="solheadingline mx-auto w-1/2 sm:w-1/3 md:w-1/4 mt-4" />
      
              <p className="testsubheading font-extrabold text-xl sm:text-2xl md:text-3xl text-gray-100 mt-4">
                We are very proud of the service we provide and stand by every service we carry.
              </p>
      
              <p className="testsublighttext text-white mt-8 text-lg sm:text-xl md:text-2xl">
                Read our testimonials from our happy customers.
              </p>
      
              <div className="reviewcontdiv mt-12">
                <Swiper
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={20}
                  slidesPerView={1} // Default to showing 1 slide at a time
                  centeredSlides={true} // Center the active slide
                  navigation
                  pagination={{ clickable: true }}
                  breakpoints={{
                    640: { slidesPerView: 1 }, // Show 1 slide for screens 640px and below
                    768: { slidesPerView: 2 }, // Show 2 slide for screens between 641px and 768px
                    1024: { slidesPerView: 3 }, // Show 3 slides for screens above 1024px
                  }}
                  initialSlide={1} // Set initial slide to index 1
                  onSlideChange={(swiper) => {
                    // Custom logic can be added here if needed
                  }}
                >
                  <SwiperSlide>
                    <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                      <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                        "In the world of digital finance, security is my top priority. SunPay has earned my trust with their robust security features and transparent policies. I feel confident knowing that my data is protected and that they adhere to the highest standards of compliance."
                      </p>
                      <br />
                      <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                        Rakesh Kumar
                      </p>
                      <p className="nam text-lg sm:text-xl md:text-2xl font-black mt-4">
                        Customer Service Point
                      </p>
                    </div>
                  </SwiperSlide>
      
                  <SwiperSlide>
                    <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                      <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                        "I’ve been using this fintech service for several months now, and it has significantly simplified my financial management. The user interface is intuitive, and the advanced security features give me peace of mind."
                      </p>
                      <br />
                      <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                      HANISH GOYAL
                      </p> 
                      <p className="nam text-lg sm:text-xl md:text-2xl font-black mt-4">
                        Krishna Telecom
                      </p>
                    </div>
                  </SwiperSlide>
      
                  <SwiperSlide>
                    <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                      <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                        "As a growing startup, finding a reliable financial partner was crucial. SunPay provided us with a scalable solution that perfectly fits our needs. Their analytics tools have given us valuable insights, allowing us to make informed financial decisions. We’ve saved both time and money thanks to their innovative approach!"
                      </p>
                      <br />
                      <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                        Kailash Goyal
                      </p>
                      <p className="nam text-lg sm:text-xl md:text-2xl font-black mt-4">
                        AG Telecom
                      </p>
                    </div>
                  </SwiperSlide>
      
                  <SwiperSlide>
                    <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                      <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                        "As a small business owner, managing finances used to be a headache. The platform is intuitive and easy to navigate, even for someone like me who isn't very tech-savvy. The features are exactly what I need, and I can access everything from my phone. Highly recommend it to anyone looking to streamline their financial operations!"
                      </p>
                      <br />
                      <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                        Priya
                      </p>
                      <p className="nam text-lg sm:text-xl md:text-2xl font-black mt-4">
                        Welcome Money Transfer
                      </p> 
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                      <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                        "I’ve been using SunPay's service for several months now, and it has significantly simplified my financial management. The user interface is intuitive, and the advanced security features give me peace of mind."
                      </p>
                      <br />
                      <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                        Jessica Williams
                      </p>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
      </section>

      {/*Footer */}
      <section>
        <Footer></Footer>
      </section>
    </div>
  )
}
