import React from 'react'
import { useState, useEffect } from 'react';
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer'

const TermsCondition = () => {

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [language, setLanguage] = useState('en'); 

  const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
          setShowNavbar(false);
      } else {
          setShowNavbar(true);
      }
      setLastScrollY(currentScrollY);
  };

  const toggleLanguage = () => {
      setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'hi' : 'en'));
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [lastScrollY]);

    return (
      <div>
          {/* Navbar */}
          <div className={`sticky-navbar ${showNavbar ? 'visible' : 'hidden'}`}>
              <StickyNavbar />
          </div>

          {/* Privacy Policy Content */}
          <div className='p-8 mt-20 bg-gradient-to-br from-red-500 to-green-300'>
              
              {/* Language Toggle Button */}
                <button 
                  className="mt-4 p-2 bg-slate-100 rounded"
                  onClick={toggleLanguage}>
                  {language === 'en' ? 'हिंदी में देखें' : 'View in English'}
                </button>

              <div className='bg-white'>
                  <div className='bg-gradient-to-r from-blue-300 to-gray-500 py-4'>
                      <h1 className='text-yellow-200 text-center text-4xl'>
                          {language === 'en' ? 'Terms and Conditions' : 'नियम और शर्तें'}
                      </h1>
                  </div>
                  <div className='bg-white p-6'>
                      <h1 className='text-3xl text-center'>
                          <b>{language === 'en' ? 'Terms & Conditions of Sunpay Technology' : 'सनपे टेक्नोलॉजी के नियम एवं शर्तें'}</b>
                      </h1>
                      <br />
                      <p className='text-lg px-12'>
                          {language === 'en' ? (
                              // English Content
                              <>
                                <p>
                                  Welcome to SunPay Technology. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do not use our services.
                                  Sunpay is accessible from <a href="https://sunpay.co.in/" className='text-blue-500 underline'>sunpay.co.in</a> one of our main priorities is the privacy of our visitors.<br /> <br />

                                  <ul className='list-disc'>
                                    <b className='text-2xl'>Information we collect</b><br />

                                    <li className='ml-8'>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</li>
                                    <li className='ml-8'>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</li>
                                    <li className='ml-8'>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</li>
                                    <li className='ml-8'>We need an image for KYC purposes, so you'll need to upload an image from our website.</li>
                                  </ul>

                                  <br /> 
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>How we use your information ?</b> <br />

                                    <li className='ml-8'>We use the information we collect in various ways, including to Provide, operate, and maintain our website </li>
                                    <li className='ml-8'>Improve, personalize, and expand our website </li>
                                    <li className='ml-8'>nderstand and analyse how you use our website </li>
                                    <li className='ml-8'>Develop new products, services, features, and functionality</li>
                                    <li className='ml-8'>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                    <li className='ml-8'>Send you emails</li>
                                    <li className='ml-8'>Find and prevent fraud</li>
                                    <li className='ml-8'>Camera and Photo</li>
                                    <li className='ml-8'>We need image for KYC purpose so, need upload image from mobile Application.</li>
                                    <li className='ml-8'>SUNPAY follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services & analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users&#39; movement on the website, and gathering demographic information.</li>
                                  </ul>
                                  
                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>Cookies and Web Beacons</b> <br />

                                    <li className='ml-8'>Like any other website, SUNPAY uses & cookies. 
                                      These cookies are used to store information including visitors & preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users & experience by customizing our web page content based on visitors & browser type and/or other information.
                                      For more general information on cookies, please read the Cookies article on TermsFeed website.
                                    </li>
                                  </ul>
                                  <br />

                                  <ul className='list-disc'>
                                    <b className='text-2xl'>Advertising Partners Privacy Policies</b> <br />

                                    <li className='ml-8'>You may consult this list to find the Privacy Policy for each of the advertising partners of SUNPAY.</li>
                                    <li className='ml-8'>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on SUNPAY, which are sent directly to users & browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</li>
                                    <li className='ml-8'>Note the SUNPAY has no access to or control over these cookies that are used by third-party advertisers.</li>
                                    <li className='ml-8'>Third Party Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</li>
                                    <li className='ml-8'> You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers & respective websites.</li>
                                  </ul>
                                  <br />
                                  
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>CCPA Privacy Rights</b> <br />
                                    <li className='ml-8'>(Do Not Sell My Personal Information) Under the CCPA, among other rights, California consumers have the right to: </li>
                                    <li className='ml-8'>Request that a business that collects a consumer & personal data disclose the categories and specific pieces of personal data that a business has collected about consumers. </li>  
                                    <li className='ml-8'>Request that a business delete any personal data about the consumer that a business has collected. Request that a business that sells a consumer & personal data, not sell the consumer & personal data.</li> 
                                    <li className='ml-8'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>   
                                  </ul> 
                                  <br />
                                  
                                <ul className='list-disc'>
                                  <b className='text-2xl'>GDPR Data Protection Rights</b> <br />
                                  <ul className='ml-8 list-disc'>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following: 
                                    <li className='ml-12'>The right to accessed</li>
                                    <li className='ml-12'>You have the right to request copies of your personal data.</li>
                                    <li className='ml-12'>We may charge you a small fee for this service. </li>
                                    <li className='ml-12'>The right to rectification & dash.</li>
                                    <li className='ml-12'>You have the right to request that we correct any information you believe is inaccurate.</li>
                                    <li className='ml-12'>You also have the right to request that we complete the information you believe is incomplete.</li>
                                    <li className='ml-12'>The right to erasure.</li>
                                    <li className='ml-12'>You have the right to request that we erase your personal data, under certain conditions.</li>
                                    <li className='ml-12'>The right to restrict processing.</li>
                                    <li className='ml-12'>You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                                    <li className='ml-12'>The right to object to processing.</li>
                                    <li className='ml-12'>You have the right to object to our processing of your personal data, under certain conditions. </li>
                                    <li className='ml-12'>The right to data portability.</li>
                                    <li className='ml-12'>You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                                  </ul>
                                </ul>  
                                <br />
                                <ul className='list-disc'>
                                  <b className='text-2xl'>Data Retention Period</b> <br />
                                  <li className='ml-8'>Sunpay retains personal data for 8 years as necessary to fulfill the purpose for which it was collected.</li>
                                  <li className='ml-8'>The need to retain data varies widely with the type of data and the purpose for which it was collected.</li>
                                  <li className='ml-8'>Sunpay strives to ensure that data is only retained for the period necessary to fulfil the purpose for which it was collected and is fully deleted when no longer required.</li>
                                  <li className='ml-8'>This policy sets forth Sunpay’s guidelines on data retention and is to be consistently applied throughout the organization. </li>
                                  <li className='ml-8'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. </li>
                                </ul>        
                                <br />     

                                <ul className='list-disc'>
                                  <b className='text-2xl'>Children Information</b> <br />

                                  <li className='ml-8'>Another part of our priority is adding protection for children while using the internet. </li>
                                  <li className='ml-8'>We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</li>
                                  <li className='ml-8'>Sunpay does not knowingly collect any Personal Identifiable Information from children under the age of 18. </li>
                                  <li className='ml-8'>If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</li>
                                </ul>
                                <br />   

                                <ul className='list-disc'>
                                  <b className='text-2xl'>Company Credit Policy</b> <br />

                                  <li className='ml-8'>Our company provides credit for a one-day period only. </li>
                                  <li className='ml-8'>Customers must reverse the payment by the same day's evening.</li>
                                  <li className='ml-8'>In the event of non-compliance, a fine of ₹100 per day will be applicable until the payment is cleared. </li>
                                  <li className='ml-8'>We appreciate your cooperation in adhering to this policy.</li>
                                </ul>                
                                </p>
                              </>
                          ) : (
                              // Hindi Content
                              <>
                                <p>
                                  Sunpay तक पहुंचने के लिए <a href="https://sunpay.co.in/" className='text-blue-500 underline'>sunpay.co.in</a> का उपयोग करें। हमारे प्रमुख उद्देश्यों में से एक हमारे आगंतुकों की गोपनीयता है।

                                  यह गोपनीयता नीति दस्तावेज़ SUNPAY द्वारा एकत्रित और रिकॉर्ड की जाने वाली जानकारी के प्रकारों को शामिल करता है और हम इसका उपयोग कैसे करते हैं। यदि आपके पास अधिक प्रश्न हैं या हमारी गोपनीयता नीति के बारे में अधिक जानकारी की आवश्यकता है, तो कृपया हमसे संपर्क करने में संकोच न करें।
                                  <br /><br />
                                  यह गोपनीयता नीति केवल हमारी ऑनलाइन गतिविधियों पर लागू होती है और हमारी वेबसाइट के आगंतुकों के लिए मान्य है, जो SUNPAY में साझा और/या एकत्रित जानकारी के बारे में है। यह नीति ऑफलाइन या इस वेबसाइट के अलावा अन्य चैनलों के माध्यम से एकत्र की गई किसी भी जानकारी पर लागू नहीं है। हमारी वेबसाइट का उपयोग करके, आप हमारी गोपनीयता नीति से सहमत हैं और इसके नियमों को स्वीकार करते हैं।
                                  <br /><br />

                                  <ul className='list-disc'>
                                    <b className='text-2xl'>हम कौन-कौन सी जानकारी एकत्र करते हैं</b><br />
                                    <li className='ml-8'>आपसे पूछी गई व्यक्तिगत जानकारी और इसे प्रदान करने का कारण आपको उस समय स्पष्ट कर दिया जाएगा जब हम आपसे आपकी व्यक्तिगत जानकारी प्रदान करने के लिए कहते हैं।</li>
                                    <li className='ml-8'>यदि आप हमसे सीधे संपर्क करते हैं, तो हमें आपके बारे में अतिरिक्त जानकारी प्राप्त हो सकती है, जैसे कि आपका नाम, ईमेल पता, फोन नंबर, संदेश और/या अटैचमेंट्स जो आप हमें भेज सकते हैं, और कोई अन्य जानकारी जिसे आप प्रदान करने का निर्णय लेते हैं।</li>
                                    <li className='ml-8'>जब आप एक खाता पंजीकृत करते हैं, तो हम आपसे संपर्क जानकारी मांग सकते हैं, जैसे कि नाम, कंपनी का नाम, पता, ईमेल पता, और टेलीफोन नंबर।</li>
                                    <li className='ml-8'>केवाईसी उद्देश्यों के लिए हमें एक छवि की आवश्यकता है, इसलिए आपको हमारी वेबसाइट से एक छवि अपलोड करनी होगी।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>हम आपकी जानकारी का उपयोग कैसे करते हैं?</b><br />
                                    <li className='ml-8'>हम जानकारी का उपयोग विभिन्न तरीकों से करते हैं, जिसमें हमारी वेबसाइट प्रदान करना, संचालित करना और बनाए रखना शामिल है।</li>
                                    <li className='ml-8'>हमारी वेबसाइट को बेहतर बनाना, उसे व्यक्तिगत बनाना, और उसका विस्तार करना।</li>
                                    <li className='ml-8'>हमारी वेबसाइट का उपयोग कैसे किया जाता है, इसे समझना और विश्लेषण करना।</li>
                                    <li className='ml-8'>नए उत्पाद, सेवाएं, सुविधाएं और कार्यक्षमता विकसित करना।</li>
                                    <li className='ml-8'>आपसे सीधे या हमारे भागीदारों के माध्यम से संवाद करना, जिसमें ग्राहक सेवा, वेबसाइट से संबंधित अपडेट, और विपणन और प्रचार उद्देश्यों के लिए संचार शामिल है।</li>
                                    <li className='ml-8'>आपको ईमेल भेजना।</li>
                                    <li className='ml-8'>धोखाधड़ी का पता लगाना और रोकना।</li>
                                    <li className='ml-8'>कैमरा और फोटो के लिए, केवाईसी के लिए छवि अपलोड करना आवश्यक है।</li>
                                    <li className='ml-8'>SUNPAY एक मानक प्रक्रिया का अनुसरण करता है जो लॉग फाइल्स का उपयोग करता है। जब आगंतुक वेबसाइट पर जाते हैं, तो इन फाइल्स में लॉगिंग की जाती है। सभी होस्टिंग कंपनियां और होस्टिंग सेवाएं इसका उपयोग करती हैं और इसे एनालिटिक्स का हिस्सा मानती हैं। लॉग फाइल्स द्वारा एकत्र की गई जानकारी में इंटरनेट प्रोटोकॉल (IP) पते, ब्राउज़र प्रकार, इंटरनेट सेवा प्रदाता (ISP), तिथि और समय स्टैंप, संदर्भ/निकासी पृष्ठ और संभावित क्लिक की संख्या शामिल हैं। इनका उपयोग रुझानों के विश्लेषण, साइट के संचालन, वेबसाइट पर उपयोगकर्ताओं के मूवमेंट को ट्रैक करने और जनसांख्यिकी जानकारी एकत्र करने के उद्देश्य से किया जाता है।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>कुकीज और वेब बीकन</b><br />
                                    <li className='ml-8'>SUNPAY की तरह, अन्य वेबसाइटें भी कुकीज का उपयोग करती हैं। इन कुकीज का उपयोग आगंतुकों की प्राथमिकताओं को संग्रहीत करने और वेबसाइट पर वे किस पृष्ठ पर गए, उसकी जानकारी संग्रहीत करने के लिए किया जाता है। जानकारी का उपयोग आगंतुकों के अनुभव को अनुकूलित करने के लिए किया जाता है।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>विज्ञापन साझेदारों की गोपनीयता नीतियां</b><br />
                                    <li className='ml-8'>SUNPAY के विज्ञापन साझेदारों के लिए गोपनीयता नीति को देखने के लिए आप इस सूची का उपयोग कर सकते हैं।</li>
                                    <li className='ml-8'>तृतीय-पक्ष विज्ञापन सर्वर या विज्ञापन नेटवर्क कुकीज, जावास्क्रिप्ट, या वेब बीकन जैसी तकनीकों का उपयोग करते हैं।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>CCPA गोपनीयता अधिकार</b><br />
                                    <li className='ml-8'>CCPA के तहत, कैलिफोर्निया के उपभोक्ताओं को अधिकार हैं।</li>
                                    <li className='ml-8'>व्यवसाय से अपने डेटा का खुलासा करना और इसे हटाना अनुरोध कर सकते हैं।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>GDPR डेटा सुरक्षा अधिकार</b><br />
                                    <ul className='ml-8 list-disc'>
                                      <li className='ml-12'>सभी उपयोगकर्ताओं के पास ये अधिकार हैं।</li>
                                    </ul>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>DRP</b><br />
                                    <li className='ml-8'>Sunpay केवल आवश्यक डेटा बनाए रखने के लिए प्रयत्नशील है।</li>
                                    <li className='ml-8'>अनुरोध के समय हम एक महीने में जवाब देंगे।</li>
                                  </ul>

                                  <br />
                                  <ul className='list-disc'>
                                    <b className='text-2xl'>बच्चों की जानकारी</b><br />
                                    <li className='ml-8'>Sunpay बच्चों के लिए सुरक्षा बढ़ाने के लिए प्रयास करता है।</li>
                                  </ul>
                                </p>
                              </>

                          )}
                      </p>
                  </div>
              </div>
          </div>

          {/* Footer */}
          <section className='Footer'>
              <Footer />
          </section>
      </div>
    );
}

export default TermsCondition
