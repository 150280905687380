import React from 'react'
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import wmoreimg from './Images Homepage/wh-img-1.jpg'
import s1 from './Images Homepage/service-1.jpg'
import s2 from './Images Homepage/service-2.jpg'
import s3 from './Images Homepage/service-3.jpg'
import s4 from './Images Homepage/service-4.jpg'
import s5 from './Images Homepage/service-5.jpg'
import s6 from './Images Homepage/service-6.jpg'
import aeps from './Images Homepage/aeps.png'
import s8 from './Images Homepage/serviceflight.png'
import s7 from './Images Homepage/servicebus.png'
import s9 from './Images Homepage/servicehotel.png'

const Servicepage = () => {
  return (
    <div>
      <section>
        <StickyNavbar></StickyNavbar>
      </section>

      <section>
        {/* Service Section */}
        <section className='servicesectionhome bg-white pt-28 pb-16'>
            <div className="homservseccont w-full">
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl ml-4 sm:ml-8 md:ml-12 text-gray-900 font-bold"><b>Our Services</b></h1>
              <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl ml-4 sm:ml-8 md:ml-12 text-slate-200">Take Business Services From Our Experienced Staff</h1>

              <div className='homeservslidecont mx-4 mt-6'>
                <Swiper
                  className='rounded-lg'
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={20}
                  slidesPerView={1}
                  centeredSlides={true}
                  navigation
                  pagination={{ clickable: true }}
                  breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                  initialSlide={0} // Set the first slide as active
                >
                  <SwiperSlide>
                    <div className="swiperslide1 px-8 py-4 ">
                      <div className='swiperslide-content rounded-3xl p-6 bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s2} alt=""  className='w-full h-52'/>
                        <h1 className='font-black text-4xl'>AEPS</h1>
                        <br />
                        <p className='text-xl'>
                        Aadhaar Enabled Payment System (AEPS) is a sort of installment framework that depends on the Unique Identification Number and permits Aadhaar card holders to consistently make money related exchanges through Aadhaar-based confirmation.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
      
                  <SwiperSlide>
                    <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                      <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s6} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                        <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Travel Booking</h1>
                        <p className='text-lg mt-4'>
                          Travel API is fundamentally a set of web services to access travel deals from various consolidators. GDS, third-party flight APIs, and hotel APIs are used by travel agencies to access deals online.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                      <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s3} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                        <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Recharge & Utility Bill Payments</h1>
                        <p className='text-lg mt-4'>
                          We offer hassle-free mobile recharge and utility bill payment services. Pay for electricity, water, gas, DTH, and mobile bills instantly with ease.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4 ">
                      <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s9} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                        <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Hotel Booking</h1>
                        <p className='text-lg mt-4'>
                          We provide hotel booking services in India for various accommodations, including budget hotels, luxury hotels, and resorts.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                      <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s8} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                        <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Flight Booking</h1>
                        <p className='text-lg mt-4'>
                          We provide online flight booking services. Our service covers major domestic and international airlines, ensuring a seamless booking experience.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                      <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                        <img src={s7} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                        <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Bus Booking</h1>
                        <p className='text-lg mt-4'>
                          We provide online bus booking services. Our service covers major bus operators across the country, ensuring a seamless booking experience.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
        </section>

        {/* More About Section */}
        <section className='whysunp bg-slate-50'>
          <div className='whysunpcont md:flex flex-col md:flex-row pt-12 pb-12 px-4 sm:px-8 md:px-12 lg:px-24'>
            <div className='lwhysunp w-full md:w-1/2'>
              <p className='text-red-600 text-lg sm:text-xl'>WHY CHOOSE US?</p><br />
              <h1 className='text-3xl sm:text-4xl font-black w-full sm:w-5/6'>
                We Offer Best Professional Services For Your Business
              </h1>
              <div className='leftwhysunpitemcont mt-8 sm:mt-12'>
                <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>B2B Platform</h1>
                    <p className='text-sm sm:text-base'>
                      Build your own Retailer Distributor Network with bug free mobile recharge software.
                    </p>              
                  </div>
                </div>

                <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>B2C Portals</h1>
                    <p className='text-sm sm:text-base'>
                      Online recharge portal connected directly with mobile, DTH, data card, Post Paid bill payment, electricity and utility payment platforms.
                    </p>
                  </div>
                </div>

                <div className='lwhysunpitem w-full sm:w-3/4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>Android</h1>
                    <p className='text-sm sm:text-base'>
                      Multi recharge software includes Android application as standard feature of software. Publish your own brand name on Playstore.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='rwhysunpimg mt-4 md:mt-12 w-full md:w-1/2 border-4 border-zinc-600'>
                    <img src={wmoreimg} alt="" className='w-full h-[500px]'/>
              <div className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl bg-white text-center bottom-0 w-full py-4'>
                100% Trusted Company
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <Footer></Footer>
      </section>
    </div>
  )
}

export default Servicepage
