import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './CSS/Login.css';
// import DashboardRouter from './Dashboard/DashboardRouter'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import navlogo from '../../Navbar/NavImages/logo.png';
import usernavimg from '../../Navbar/NavImages/user.png';
import sunptext from './Loginpage Img/Sunpaytextlogin.png';
import logimg from './Loginpage Img/Money_Transfer.png';

const Login = ({ setIsLoggedIn, IsLoggedIn }) => {
  const [login_id, setlogin_id] = useState('');
  const [password, setPassword] = useState('');
  const [pin, setpin] = useState('');
  const [otp, setotp] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [istpinvis, settpinvisible] = useState(false);
  const [mobile, setmobile] = useState('');
  const [upwd, setpasswd] = useState('');
  const [browserdata, setbrowserdata] = useState('');
  const [uid, setuid] = useState('');
  const [api_token, settoken] = useState('');
  const [name, setname] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      // Redirect to the same page or perform any other action
      navigate('/member/login');
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);
  
  const handleLogin = async () => {
    const formData = new FormData();
    formData.append('mobile', login_id);
    formData.append('password', password);
    formData.append('longitude', '12.12');
    formData.append('latitude', '12.12');
  
    try {
      const response = await fetch('https://dev.sunpay.co.in/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json', // Keep only Accept header for FormData
        },
        body: formData,
      });
  
      const data = await response.json();
      // Handle API response based on `message` parameter
      if (data.message === "otp send successfully done" && data.status === "success") {
        setmobile(data.mobile);
        setbrowserdata(data.borwserData);
        setuid(data.id);
        setpasswd(data.upwd);
        setIsVisible(true);  // Show OTP input field
        settpinvisible(false); // Hide Tpin field
      } else if (data.message === "Invalid login details") {
        alert("Invalid details. Please try again!");
      } else {
        alert(data.message || "Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error('Login Error:', error);
      alert("Network error. Please check your connection and try again.");
    }
  };
  
  const handletotp = async () => {
    const formData = new FormData();
    formData.append('otp', otp);
    formData.append('mobile', mobile);
    formData.append('password', upwd); // Encrypted password
    formData.append('browserData', browserdata);
    formData.append('lat', '12.12');
    formData.append('long', '12.12');
    formData.append('uid', uid);
  
    try{
        const response = await fetch('https://dev.sunpay.co.in/api/login/validate', {
            method: 'POST',
            headers: {
              'Accept': 'application/json', // No need for Content-Type with FormData
            },
            body: formData,
        });
      
        const data = await response.json();
          
        if (data.message === "Successfully login" && data.status === 'success'){
            localStorage.setItem('apiData', JSON.stringify(data.api_token));
            console.log("navigate to dashboard")
            setIsLoggedIn(true);
            navigate('/member/dashboard', {
              state: { data: data, IsLoggedIn: true },
            });
        }
      }
    catch (error) {
      console.error('OTP Validation Error:', error);
      alert('An error occurred while verifying OTP. Please try again.');
    }
  };
  
  
  const handletpin = async () => {
    try {
      const tpin = parseInt(pin);
      const response = await fetch('https://sunpay.co.in/api/tpin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login_id, password, tpin }),
      });
      const result = await response.json();
      if (result.message === 'Login Successful') {
        const apidata = result.data;
        localStorage.setItem('apiData', JSON.stringify(apidata));           
         
          if(apidata.role_id===1 || apidata.role_id===2){
            navigate('/member/dashboard', {
              state: { data: apidata  },
            });
        } 
      } 
      else {
        alert('Invalid Credentials');
      }
    } catch (error) {
      console.error('Invalid TPIN:', error);
    }
  };

  const handleback = async () => {
    setIsVisible(false);
  };

  return (
    <div className='loginpage w-full pt-24 pb-6 bg-gray-100 min-h-screen'>
      {/* Navbar */}
      <nav className='w-full fixed top-0 left-0 bg-white shadow-md z-50'>
        <div className='max-w-screen-xl flex items-center justify-between p-4'>
          <NavLink to='/' className='flex items-center'>
            <img src={navlogo} className='h-10' alt='Logo' />
          </NavLink>
          <div className='hidden md:flex space-x-6 text-lg'>
            <NavLink to='/' className='hover:text-blue-700'>Home</NavLink>
            <NavLink to='/about-us' className='hover:text-blue-700'>About</NavLink>
            <NavLink to='/services' className='hover:text-blue-700'>Services</NavLink>
            <NavLink to='/contact-us' className='hover:text-blue-700'>Contact</NavLink>
            {/* <NavLink to='/try' className='hover:text-blue-700'>Join Us</NavLink> */}
          </div>
          <button className='md:hidden text-gray-600 focus:outline-none'>
            ☰
          </button>
        </div>
      </nav>
      
      {/* Login Section */}
      <section className='loginsection flex flex-col items-center mt-24 px-6 md:flex-row md:px-12 md:space-x-12'>
        <div className='w-full md:w-1/2'>
          <img src={logimg} className='w-full h-auto' alt='Login' />
        </div>
        <div className='w-full md:w-1/2 bg-white p-6 py-12 rounded-lg shadow-lg'>
          {!isVisible ? (
            <div>
              <p className='text-4xl font-bold text-center mb-4'>Sign in to Continue</p>
              <input
                type='text'
                value={login_id}
                onChange={(e) => setlogin_id(e.target.value)}
                className='w-full p-2 border rounded mb-4'
                placeholder='Enter Your Mobile Number'
                maxLength='10'
              />
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='w-full p-2 border rounded mb-4'
                placeholder='Enter Your Password'
              />
              <button
                className='w-full bg-blue-600 hover:bg-blue-700 text-white p-2 rounded'
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          ) : (
            <div>
              {istpinvis ? (
                <div>
                  <label className='block font-semibold mb-2'>Tpin:</label>
                  <input
                    type='password'
                    value={pin}
                    onChange={(e) => setpin(e.target.value)}
                    className='w-full p-2 border rounded mb-4'
                    maxLength='4'
                  />
                  <button className='w-full bg-blue-600 text-white p-2 rounded' onClick={handletpin}>Submit</button>
                </div>
              ) : (
                <div>
                  <label className='block font-semibold mb-2 text-4xl'>OTP:</label>
                  <input
                    type='password'
                    value={otp}
                    onChange={(e) => setotp(e.target.value)}
                    className='w-full p-2 border rounded mb-4'
                    maxLength='6'
                  />
                  <button className='w-full bg-blue-600 text-white p-2 rounded' onClick={handletotp}>Submit</button>
                </div>
              )}
              <button className='w-full mt-2 bg-gray-500 text-white p-2 rounded' onClick={handleback}>Back</button>
            </div>
          )}
        </div>

      </section>
    </div>
  );
};

export default Login;