import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './NewsTicker.css';
import Navbar from '../DashboardComponents/Navbar/Navbar';
import Sidebar from '../DashboardComponents/Sidebar/Sidebar';
import Main from '../DashboardComponents/Main/Main';
import Fundrequest from '../../Fundrequest';
import Reports from '../../Reports';
import Accountlagger from '../../Accountlagger';
import Payout from '../../Payout';
import Payoutreport from '../../Payout-DMT';
import Complaint from '../../Complaint';
import AEPSreport from '../../AEPSreport';
import DMTreport from '../../DMTreport';
import Rechargeutilityreport from '../../Rechargeutilityreport';
import Moneytransfer from '../../Moneytransfer';
import Addbeneficiary from '../../Addbeneficiary';
import Confirmmoneytransfer from '../../Confirmmoneytransfer';
import Moneytransferreceipt from '../../Moneytransferreceipt';
import Memberprofile from '../../Memberprofile';
import MemberSettings from '../../MemberSettings';
import Creditcard from '../../Creditcard';
import Electricity from '../../Electricity';
import Gas from '../../Gas';
import Broadband from '../../Broadband';
import Water from '../../Water';
import UPItransfer from '../../UPItransfer';
import Billpaymentreceipt from '../../Billpaymentreceipt';
import Prepaid from '../../Prepaid';
import Postpaid from '../../Postpaid';
import Fastag from '../../Fastag';
import Wallettransfer from '../../Wallettransfer';
import LIC from '../../LIC';
import DTH from '../../DTH';
import Educationfee from '../../Educationfee';
import Telephone from '../../Telephone';
import STV from '../../STV';
import Trafficchallan from '../../Trafficchallan';
import Digitalvoucher from '../../Digitalvoucher';
import Cable from '../../Cable';
import Datacard from '../../Datacard';
import Loanpayment from '../../Loanpayment';
import Mcdtollservics from '../../Mcdtollservics'
import Insurance from '../../Insurance';
import AddCustomer from '../../Addcustomer';
import Retailerregister from '../../Retailerregister';
import ConfirmPayout from '../../ConfirmPayout';
import WalletTransactionreport from '../../WalletTransactionreport';
import Fundreqreport from '../../Fundreqreport';
import AddUPI from '../../AddUPI';
import FundDebit from '../../FundDebit';
import FundCredit from '../../FundCredit';
import { TbLocationBroken } from 'react-icons/tb';

const MemberRouter = ({ IsLoggedIn, ...props }) => {

  const { category } = useParams();

  const [token, settoken] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem('apiData');
    console.log('browserdata', storedData)
    if (storedData) {
      settoken(JSON.parse(storedData))
      console.log(token)
    }
  }, []);

  return (
    
    <div className='bg-slate-50'> 
      <div className=''>
        <Navbar data={TbLocationBroken}/>
      </div>
     <div className='flex w-full gap-1 overflow-visible bg-black'>
        <div className='bg-red-600 w-1/2 text-2xl md:mt-[90px] text-center text-black '>
            News HeadLine
          </div>
          <div className='bg-red-600 w-1/2 text-2xl md:mt-[90px] text-center text-black news-ticker '>
            <p className='animate-scroll-left-to-right news-ticker-content'> Small News HeadLine</p>
          </div>
     </div>
      <div className='main h-screen w-full gap-2 flex flex-1'>
        <aside className='sticky top-0'>
          <Sidebar data={token}/>
        </aside>

        <div className={`main w-full overflow-scroll scrollbar-hide z-10`}>
          {category === 'dashboard' && <Main data={token}/>}
          {category === 'createretailer' && (<Retailerregister data={token}/>)}
          {category === 'profile' && <Memberprofile data={token} />}
          {category === 'fund-request' && <Fundrequest data={token} />}
          {category === 'fundreport' && (<Fundreqreport data={token}/>)}
          {category === 'reports' && <Reports data={token} />}
          {category === 'payoutDMT' && <Payout data={token} />}
          {category === 'confirmpayout' && <ConfirmPayout data={token} />}
          {category === 'accountlagger' && <Accountlagger data={token} />}
          {category === 'complaints' && <Complaint data={token} />}
          {category === 'settings' && <MemberSettings data={token} />}
          {category === 'aeps-history' && <AEPSreport data={token} />}
          {category === 'dmt-history' && <DMTreport data={token} />}
          {category === 'wallettowallet-history' && (<WalletTransactionreport data={token}/>)}
          {category === 'payout-report' && <Payoutreport data={token} />}
          {category === 'recharge-history' && <Rechargeutilityreport data={token} />}
          {category === 'moneytransfer' && <Moneytransfer data={token} />}
          {category === 'wallet-to-wallet' && <Wallettransfer data={token} />}
          {category === 'moneytransferreceipt' && (<Moneytransferreceipt data={token}/>)}
          {category === 'credithistory' && <Creditcard data={token} />}
          {category === 'addcustomer' && (<AddCustomer data={token}/>)}
          {category === 'addnewbeneficiary' && (<Addbeneficiary data={token}/>)}
          {category === 'electricity' && (<Electricity data={token}/>)}
          {category === 'water' && (<Water data={token}/>)}
          {category === 'lic' && (<LIC data={token}/>)}
          {category === 'gas' && (<Gas data={token}/>)}          
          {category === 'broadband' && (<Broadband data={token}/>)}
          {category === 'dth' && (<DTH data={token}/>)}
          {category === 'voucher' && (<Digitalvoucher data={token}/>)}
          {category === 'telephone' && (<Telephone data={token}/>)}
          {category === 'fee' && (<Educationfee data={token}/>)}
          {category === 'stv' && (<STV data={token}/>)}
          {category === 'traffic_challan' && (<Trafficchallan data={token}/>)}
          {category === 'cable' && (<Cable data={token}/>)}
          {category === 'datacard' && (<Datacard data={token}/>)}
          {category === 'loanrepayment' && (<Loanpayment data={token}/>)}
          {category === 'mcdtax' && (<Mcdtollservics data={token}/>)}
          {category === 'insurance' && (<Insurance data={token}/>)}
          {category === 'upi-transfer' && <UPItransfer data={token} />}
          {category === 'confirmdmt' && <Confirmmoneytransfer data={token} />}
          {category === 'billpayreceipt' && <Billpaymentreceipt data={token} />}
          {category === 'upireceipt' && <Billpaymentreceipt data={token} />}
          {category === 'prepaid' && <Prepaid data={token} />}
          {category === 'postpaid' && <Postpaid data={token} />}
          {category === 'fastag' && <Fastag data={token} />}
          {category === 'addupi' && <AddUPI data={token} />}
          {category === 'fund-credit' && <FundCredit data={token} />}
          {category === 'fund-debit' && <FundDebit data={token} />}
        </div>
      </div>

      
    </div>
  );
};

export default MemberRouter;
